"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
require("@/node_modules/angularjs-slider/dist/rzslider.js");
const SoundBox = require('sound-box').default;
const random = (min, max, exclude) => {
    let r = Math.floor(Math.random() * (max + 1 - min)) + min;
    while ((r == exclude) && (max - min) >= 1) {
        r = Math.floor(Math.random() * (max + 1 - min)) + min;
    }
    return r;
};
class SoundsCtrl {
    constructor($scope, $filter, $q) {
        this.$scope = $scope;
        this.$filter = $filter;
        this.$q = $q;
        this.version = 1;
        this.defaultLevel = 80;
        this.sliderOptions = {
            floor: 0,
            ceil: 100,
            step: 1,
            hideLimitLabels: true,
            hidePointerLabels: true,
            defaultVolume: this.defaultLevel,
            onChange: ((sliderId, modelValue, highValue, pointerType) => {
                const volume = modelValue / 100;
                this.soundboxMap[sliderId.key].instances.map((audio) => {
                    audio.volume = volume;
                });
            }).bind(this),
            // onEnd: ((sliderId: ISound, modelValue: any, highValue: any, pointerType: any) => {
            //     sliderId.level = modelValue
            // }).bind(this),
        };
        this.presets = {
            productivity: [
                [
                    { key: 'frogs', level: 25 },
                    { key: 'rain', level: 75 },
                ],
                [
                    { key: 'campfire', level: 25 },
                    { key: 'rain', level: 75 },
                ],
            ],
            relax: [
                [
                    { key: 'rain2', level: 98 },
                ],
                [
                    { key: 'frogs', level: 37 },
                    { key: 'campfire', level: 65 },
                ],
            ],
            meditation: [
                [
                    { key: 'frogs', level: 37 },
                    { key: 'rain', level: 65 },
                    { key: 'campfire', level: 65 },
                ],
            ],
            sleep: [
                [
                    { key: 'thunder', level: 37 },
                    { key: 'campfire', level: 65 },
                ],
            ],
        };
        this.soundboxMap = {};
        this.presetSelected = {};
        this.sounds = [
            Object.assign({ key: 'rain', sound: require('./sounds/rain.mp3').default, iconClass: 'fa-light fa-cloud-showers-heavy', title: this.$filter('translate')('Light rain'), level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`rain_${this.version}`) || '{}')),
            Object.assign({ key: 'rain2', sound: require('./sounds/rain2.mp3').default, iconClass: 'fa-light fa-cloud-showers-heavy', title: this.$filter('translate')('Rain on roof'), level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`rain2_${this.version}`) || '{}')),
            Object.assign({ key: 'rain3', sound: require('./sounds/rain3.mp3').default, iconClass: 'fa-light fa-cloud-showers-heavy', title: this.$filter('translate')('Rain on windowsill'), level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`rain3_${this.version}`) || '{}')),
            Object.assign({ key: 'rain4', sound: require('./sounds/rain4.mp3').default, iconClass: 'fa-light fa-cloud-showers-heavy', title: this.$filter('translate')('Rain in jungle'), level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`rain4_${this.version}`) || '{}')),
            Object.assign({ key: 'frogs', sound: require('./sounds/frogs.mp3').default, title: this.$filter('translate')('Croaking frogs'), iconClass: 'fa-light fa-frog', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`frogs_${this.version}`) || '{}')),
            Object.assign({ key: 'campfire', sound: require('./sounds/campfire.mp3').default, title: this.$filter('translate')('Crackling campfire'), iconClass: 'fa-light fa-fire', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`campfire_${this.version}`) || '{}')),
            Object.assign({ key: 'fireplace', sound: require('./sounds/fireplace.mp3').default, title: this.$filter('translate')('Cozy fireplace'), iconClass: 'fa-light fa-cloud-bolt', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`fireplace_${this.version}`) || '{}')),
            Object.assign({ key: 'thunder', sound: require('./sounds/thunder.mp3').default, title: this.$filter('translate')('Rolling thunder'), iconClass: 'fa-light fa-cloud-bolt', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`thunder_${this.version}`) || '{}')),
            Object.assign({ key: 'creek', sound: require('./sounds/creek.mp3').default, title: this.$filter('translate')('Bubbling creek'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`creek_${this.version}`) || '{}')),
            Object.assign({ key: 'river', sound: require('./sounds/river.mp3').default, title: this.$filter('translate')('Mountain river'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`river_${this.version}`) || '{}')),
            Object.assign({ key: 'beach', sound: require('./sounds/beach.mp3').default, title: this.$filter('translate')('Tropical beach'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`beach_${this.version}`) || '{}')),
            Object.assign({ key: 'waves', sound: require('./sounds/waves.mp3').default, title: this.$filter('translate')('Gentle waves'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`waves_${this.version}`) || '{}')),
            Object.assign({ key: 'lake', sound: require('./sounds/lake.mp3').default, title: this.$filter('translate')('Calm lake'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`lake_${this.version}`) || '{}')),
            Object.assign({ key: 'wind', sound: require('./sounds/wind.mp3').default, title: this.$filter('translate')('Whistling wind'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`wind_${this.version}`) || '{}')),
            Object.assign({ key: 'wind2', sound: require('./sounds/wind2.mp3').default, title: this.$filter('translate')('Rustling leaves'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`wind2_${this.version}`) || '{}')),
            Object.assign({ key: 'wind3', sound: require('./sounds/wind3.mp3').default, title: this.$filter('translate')('Winter wind'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`wind3_${this.version}`) || '{}')),
            Object.assign({ key: 'wind_chimes', sound: require('./sounds/wind_chimes.mp3').default, title: this.$filter('translate')('Wind chimes'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`wind_chimes_${this.version}`) || '{}')),
            Object.assign({ key: 'dripping_water', sound: require('./sounds/dripping_water.mp3').default, title: this.$filter('translate')('Dripping water'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`dripping_water_${this.version}`) || '{}')),
            Object.assign({ key: 'trickle_of_water', sound: require('./sounds/water_trickle.mp3').default, title: this.$filter('translate')('Water trickle'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`water_trickle_${this.version}`) || '{}')),
            Object.assign({ key: 'cave', sound: require('./sounds/cave.mp3').default, title: this.$filter('translate')('Damp cave'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`cave_${this.version}`) || '{}')),
            Object.assign({ key: 'summer_night', sound: require('./sounds/summer_night.mp3').default, title: this.$filter('translate')('Summer night'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`summer_night_${this.version}`) || '{}')),
            Object.assign({ key: 'crickets', sound: require('./sounds/crickets.mp3').default, title: this.$filter('translate')('Chirping crickets'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`crickets_${this.version}`) || '{}')),
            Object.assign({ key: 'owls', sound: require('./sounds/owls.mp3').default, title: this.$filter('translate')('Hooting owls'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`owls_${this.version}`) || '{}')),
            Object.assign({ key: 'forest', sound: require('./sounds/forest.mp3').default, title: this.$filter('translate')('Tropical forest'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`forest_${this.version}`) || '{}')),
            Object.assign({ key: 'forest2', sound: require('./sounds/forest2.mp3').default, title: this.$filter('translate')('Ancient forest'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`forest2_${this.version}`) || '{}')),
            Object.assign({ key: 'forest3', sound: require('./sounds/forest3.mp3').default, title: this.$filter('translate')('Dense forest'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`forest3_${this.version}`) || '{}')),
            Object.assign({ key: 'birds', sound: require('./sounds/birds.mp3').default, title: this.$filter('translate')('Chirping birds'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`birds_${this.version}`) || '{}')),
            Object.assign({ key: 'forest_walk', sound: require('./sounds/forest4.mp3').default, title: this.$filter('translate')('Forest walk'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`forest4_${this.version}`) || '{}')),
            Object.assign({ key: 'jungle', sound: require('./sounds/jungle.mp3').default, title: this.$filter('translate')('Wild jungle'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`jungle_${this.version}`) || '{}')),
            Object.assign({ key: 'jungle2', sound: require('./sounds/jungle2.mp3').default, title: this.$filter('translate')('Dangerous jungle'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`jungle2_${this.version}`) || '{}')),
            Object.assign({ key: 'waterfall', sound: require('./sounds/waterfall.mp3').default, title: this.$filter('translate')('Raging waterfall'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`waterfall_${this.version}`) || '{}')),
            Object.assign({ key: 'bees', sound: require('./sounds/bees.mp3').default, title: this.$filter('translate')('Buzzing bees'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`bees_${this.version}`) || '{}')),
            Object.assign({ key: 'sheep', sound: require('./sounds/sheep.mp3').default, title: this.$filter('translate')('Bleating sheep'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`sheep_${this.version}`) || '{}')),
            Object.assign({ key: 'cicadas', sound: require('./sounds/cicadas.mp3').default, title: this.$filter('translate')('Chirping cicadas'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`cicadas_${this.version}`) || '{}')),
            Object.assign({ key: 'purr', sound: require('./sounds/purring.mp3').default, title: this.$filter('translate')('Cat’s purring'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`purring_${this.version}`) || '{}')),
            Object.assign({ key: 'village_dogs', sound: require('./sounds/village_dogs.mp3').default, title: this.$filter('translate')('Village dogs'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`village_dogs_${this.version}`) || '{}')),
            Object.assign({ key: 'whales', sound: require('./sounds/whales.mp3').default, title: this.$filter('translate')('Singing whales'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`whales_${this.version}`) || '{}')),
            Object.assign({ key: 'seagulls', sound: require('./sounds/seagulls.mp3').default, title: this.$filter('translate')('Screeching seagulls'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`seagulls_${this.version}`) || '{}')),
            Object.assign({ key: 'rooster', sound: require('./sounds/roosters.mp3').default, title: this.$filter('translate')('Crowing roosters'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`rooster_${this.version}`) || '{}')),
            Object.assign({ key: 'city', sound: require('./sounds/city.mp3').default, title: this.$filter('translate')('City noise'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`city_${this.version}`) || '{}')),
            Object.assign({ key: 'coffeehouse', sound: require('./sounds/cafe.mp3').default, title: this.$filter('translate')('Lively cafe'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`coffeehouse_${this.version}`) || '{}')),
            Object.assign({ key: 'fan', sound: require('./sounds/fan.mp3').default, title: this.$filter('translate')('Floor fan'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`fan_${this.version}`) || '{}')),
            Object.assign({ key: 'washing_machine', sound: require('./sounds/washing_machine.mp3').default, title: this.$filter('translate')('Washing machine'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`washing_machine_${this.version}`) || '{}')),
            Object.assign({ key: 'car_alarm', sound: require('./sounds/car_alarm.mp3').default, title: this.$filter('translate')('Car alarm'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`car_alarm_${this.version}`) || '{}')),
            Object.assign({ key: 'train', sound: require('./sounds/train.mp3').default, title: this.$filter('translate')('Passenger train'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`train_${this.version}`) || '{}')),
            Object.assign({ key: 'trai2n', sound: require('./sounds/train2.mp3').default, title: this.$filter('translate')('Non-stop train'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`train2_${this.version}`) || '{}')),
            Object.assign({ key: 'subway', sound: require('./sounds/subway.mp3').default, title: this.$filter('translate')('Underground subway'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`subway_${this.version}`) || '{}')),
            Object.assign({ key: 'airplane', sound: require('./sounds/airplane.mp3').default, title: this.$filter('translate')('Flying airplane'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`airplane_${this.version}`) || '{}')),
            Object.assign({ key: 'jacuzzi', sound: require('./sounds/jacuzzi.mp3').default, title: this.$filter('translate')('Bubbling jacuzzi'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`jacuzzi_${this.version}`) || '{}')),
            Object.assign({ key: 'shower', sound: require('./sounds/shower.mp3').default, title: this.$filter('translate')('Running shower'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`shower_${this.version}`) || '{}')),
            Object.assign({ key: 'fireworks', sound: require('./sounds/fireworks.mp3').default, title: this.$filter('translate')('Holiday fireworks'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`fireworks_${this.version}`) || '{}')),
            Object.assign({ key: 'park', sound: require('./sounds/park.mp3').default, title: this.$filter('translate')('Park ambience'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`park_${this.version}`) || '{}')),
            Object.assign({ key: 'playground', sound: require('./sounds/playground.mp3').default, title: this.$filter('translate')('Playground ambience'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`playground_${this.version}`) || '{}')),
            Object.assign({ key: 'clock', sound: require('./sounds/clock.mp3').default, title: this.$filter('translate')('Ticking clock'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`clock_${this.version}`) || '{}')),
            Object.assign({ key: 'clock2', sound: require('./sounds/clock2.mp3').default, title: this.$filter('translate')('Grandfather clock'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`clock2_${this.version}`) || '{}')),
            Object.assign({ key: 'steps', sound: require('./sounds/steps.mp3').default, title: this.$filter('translate')('Long steps'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`steps_${this.version}`) || '{}')),
            Object.assign({ key: 'steps2', sound: require('./sounds/steps2.mp3').default, title: this.$filter('translate')('Footsteps in snow'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`steps2_${this.version}`) || '{}')),
            Object.assign({ key: 'keyboard', sound: require('./sounds/keyboard.mp3').default, title: this.$filter('translate')('Typing on keyboard'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`keyboard_${this.version}`) || '{}')),
            Object.assign({ key: 'typewriter', sound: require('./sounds/typewriter.mp3').default, title: this.$filter('translate')('Typing on typewriter'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`typewriter_${this.version}`) || '{}')),
            Object.assign({ key: 'office', sound: require('./sounds/office.mp3').default, title: this.$filter('translate')('Office ambience'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`office_${this.version}`) || '{}')),
            Object.assign({ key: 'library', sound: require('./sounds/library.mp3').default, title: this.$filter('translate')('Public library'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`library_${this.version}`) || '{}')),
            Object.assign({ key: 'construction', sound: require('./sounds/construction.mp3').default, title: this.$filter('translate')('Construction site'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`construction_${this.version}`) || '{}')),
            Object.assign({ key: 'drill', sound: require('./sounds/drill.mp3').default, title: this.$filter('translate')('Power drill'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`drill_${this.version}`) || '{}')),
            Object.assign({ key: 'church_bells', sound: require('./sounds/church_bells.mp3').default, title: this.$filter('translate')('Church bells'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`church_bells_${this.version}`) || '{}')),
            Object.assign({ key: 'christmas_bells', sound: require('./sounds/christmas_bells.mp3').default, title: this.$filter('translate')('Christmas bells'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`christmas_bells_${this.version}`) || '{}')),
            Object.assign({ key: 'christmas', sound: require('./sounds/christmas.mp3').default, title: this.$filter('translate')('Christmas ambience'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`christmas_${this.version}`) || '{}')),
            Object.assign({ key: 'lawn_mowing', sound: require('./sounds/lawn_mowing.mp3').default, title: this.$filter('translate')('Lawn mowing'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`lawn_mowing_${this.version}`) || '{}')),
            Object.assign({ key: 'highway', sound: require('./sounds/highway.mp3').default, title: this.$filter('translate')('Busy highway'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`highway_${this.version}`) || '{}')),
            Object.assign({ key: 'traffic_jam', sound: require('./sounds/traffic_jam.mp3').default, title: this.$filter('translate')('Urban traffic jam'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`traffic_jam_${this.version}`) || '{}')),
            Object.assign({ key: 'emergency', sound: require('./sounds/emergency.mp3').default, title: this.$filter('translate')('Emergency sirens'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`emergency_${this.version}`) || '{}')),
            Object.assign({ key: 'om', sound: require('./sounds/om.mp3').default, title: this.$filter('translate')('Om chanting'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`om_${this.version}`) || '{}')),
            Object.assign({ key: 'battlefield', sound: require('./sounds/battlefield.mp3').default, title: this.$filter('translate')('Bombing battlefield'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`battlefield_${this.version}`) || '{}')),
            Object.assign({ key: '432hz', sound: require('./sounds/432hz.mp3').default, title: this.$filter('translate')('Binaural beat (432 Hz)'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`432hz_${this.version}`) || '{}')),
            Object.assign({ key: '528hz', sound: require('./sounds/528hz.mp3').default, title: this.$filter('translate')('Binaural beat (528 Hz)'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`528hz_${this.version}`) || '{}')),
            Object.assign({ key: 'bowl_1min', sound: require('./sounds/bowl_1min.mp3').default, title: this.$filter('translate')('Singing bowl (1 min)'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`bowl_1min_${this.version}`) || '{}')),
            Object.assign({ key: 'bowl_3min', sound: require('./sounds/bowl_3min.mp3').default, title: this.$filter('translate')('Singing bowl (3 min)'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`bowl_3min_${this.version}`) || '{}')),
            Object.assign({ key: 'gong', sound: require('./sounds/gong.mp3').default, title: this.$filter('translate')('Melodic gong'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`gong_${this.version}`) || '{}')),
            Object.assign({ key: 'white_noise', sound: require('./sounds/white_noise.mp3').default, title: this.$filter('translate')('White noise'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`white_noise_${this.version}`) || '{}')),
            Object.assign({ key: 'pink_noise', sound: require('./sounds/pink_noise.mp3').default, title: this.$filter('translate')('Pink noise'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`pink_noise_${this.version}`) || '{}')),
            Object.assign({ key: 'brown_noise', sound: require('./sounds/brown_noise.mp3').default, title: this.$filter('translate')('Brown noise'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`brown_noise_${this.version}`) || '{}')),
            Object.assign({ key: 'underwater', sound: require('./sounds/underwater.mp3').default, title: this.$filter('translate')('Deep underwater'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`underwater_${this.version}`) || '{}')),
            Object.assign({ key: 'sonar', sound: require('./sounds/sonar.mp3').default, title: this.$filter('translate')('Echoing sonar'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`sonar_${this.version}`) || '{}')),
            Object.assign({ key: 'alien', sound: require('./sounds/alien.mp3').default, title: this.$filter('translate')('Alien ambience'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`alien_${this.version}`) || '{}')),
            Object.assign({ key: 'snore', sound: require('./sounds/snore.mp3').default, title: this.$filter('translate')('Soft snore'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`snore_${this.version}`) || '{}')),
            Object.assign({ key: 'stadium', sound: require('./sounds/stadium.mp3').default, title: this.$filter('translate')('Stadium ambience'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`stadium_${this.version}`) || '{}')),
            Object.assign({ key: 'chickens', sound: require('./sounds/chickens.mp3').default, title: this.$filter('translate')('Clucking chickens'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`chickens_${this.version}`) || '{}')),
            Object.assign({ key: 'chicken_coop', sound: require('./sounds/chicken_coop.mp3').default, title: this.$filter('translate')('Chicken coop'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`chicken_coop_${this.version}`) || '{}')),
            Object.assign({ key: 'morning_in_village', sound: require('./sounds/morning_in_village.mp3').default, title: this.$filter('translate')('Morning in village'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`morning_in_village_${this.version}`) || '{}')),
            Object.assign({ key: 'farm', sound: require('./sounds/farm.mp3').default, title: this.$filter('translate')('Rural farm'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`farm_${this.version}`) || '{}')),
            Object.assign({ key: 'tribal_chants', sound: require('./sounds/tribal_chants.mp3').default, title: this.$filter('translate')('Tribal chants'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`tribal_chants_${this.version}`) || '{}')),
            Object.assign({ key: 'taiko', sound: require('./sounds/taiko.mp3').default, title: this.$filter('translate')('Taiko beat (88 bpm)'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`taiko_${this.version}`) || '{}')),
            Object.assign({ key: 'taiko2', sound: require('./sounds/taiko2.mp3').default, title: this.$filter('translate')('Taiko beat (88 bpm)'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`taiko2_${this.version}`) || '{}')),
            Object.assign({ key: 'drum', sound: require('./sounds/drum.mp3').default, title: this.$filter('translate')('Drum beat (30 bpm)'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`drum_${this.version}`) || '{}')),
            Object.assign({ key: 'drum2', sound: require('./sounds/drum2.mp3').default, title: this.$filter('translate')('Drum beat (57 bpm)'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`drum2_${this.version}`) || '{}')),
            Object.assign({ key: 'drum3', sound: require('./sounds/drum3.mp3').default, title: this.$filter('translate')('Drum beat (57 bpm)'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`drum3_${this.version}`) || '{}')),
            Object.assign({ key: 'drum4', sound: require('./sounds/drum4.mp3').default, title: this.$filter('translate')('Drum beat (60 bpm)'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`drum4_${this.version}`) || '{}')),
            Object.assign({ key: 'drum5', sound: require('./sounds/drum5.mp3').default, title: this.$filter('translate')('Drum beat (60 bpm)'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`drum5_${this.version}`) || '{}')),
            Object.assign({ key: 'drum6', sound: require('./sounds/drum6.mp3').default, title: this.$filter('translate')('Drum beat (68 bpm)'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`drum6_${this.version}`) || '{}')),
            Object.assign({ key: 'drum7', sound: require('./sounds/drum7.mp3').default, title: this.$filter('translate')('Drum beat (80 bpm)'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`drum7_${this.version}`) || '{}')),
            Object.assign({ key: 'drum8', sound: require('./sounds/drum8.mp3').default, title: this.$filter('translate')('Drum beat (86 bpm)'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`drum8_${this.version}`) || '{}')),
            Object.assign({ key: 'drum9', sound: require('./sounds/drum9.mp3').default, title: this.$filter('translate')('Drum beat (94 bpm)'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`drum9_${this.version}`) || '{}')),
            Object.assign({ key: 'drum10', sound: require('./sounds/drum10.mp3').default, title: this.$filter('translate')('Drum beat (94 bpm)'), iconClass: 'fa-light fa-xmark', level: this.defaultLevel, play: false }, JSON.parse(localStorage.getItem(`drum10_${this.version}`) || '{}')),
        ];
        this.pause = true;
        this.canPlay = false;
    }
    $onInit() {
        this.$scope.$watch('$ctrl.sounds', (sounds) => {
            this.canPlay = sounds.filter((item) => item.play).length != 0;
            if (!this.canPlay) {
                this.pause = true;
            }
            for (let item of sounds) {
                localStorage.setItem(`${item.key}_${this.version}`, JSON.stringify({
                    level: item.level,
                    play: item.play,
                }));
                if (!item.play) {
                    this.soundboxMap[item.key].stop_all();
                }
            }
        }, true);
        this.$q.all([...this.sounds.map((item) => {
                const soundbox = new SoundBox();
                this.soundboxMap[item.key] = soundbox;
                return soundbox.load(item.key, item.sound).then(() => {
                    item.loaded = true;
                });
            })]);
    }
    getSliderOptions(item) {
        return Object.assign(Object.assign({}, this.sliderOptions), { id: item, disabled: !item.play });
    }
    togglePlay(item) {
        item.play = !item.play;
        this.pause = false;
        for (let sound of this.sounds) {
            if (sound.play) {
                this.muteOn(sound);
            }
            else {
                this.muteOff(sound);
            }
        }
    }
    toggleMute() {
        this.pause = !this.pause;
        for (let sound of this.sounds) {
            if (sound.play) {
                if (this.pause) {
                    this.muteOff(sound);
                }
                else {
                    this.muteOn(sound);
                }
            }
        }
    }
    muteOn(sound) {
        const soundbox = this.soundboxMap[sound.key];
        const instances = soundbox.instances;
        if (instances.length == 0) {
            soundbox.play(sound.key, undefined, sound.level / 100, true);
        }
        else {
            instances.map((audio) => {
                audio.muted = false;
                audio.play();
            });
        }
    }
    muteOff(sound) {
        const soundbox = this.soundboxMap[sound.key];
        soundbox.instances.map((audio) => {
            audio.muted = true;
        });
    }
    stop() {
        this.pause = true;
        for (let item of this.sounds) {
            item.play = false;
        }
    }
    activateGroup(group) {
        var _a;
        this.pause = false;
        if (!group) {
            const index = random(0, this.sounds.length - 1);
            this.sounds.map((item, i) => {
                if (i === index) {
                    item.play = true;
                    item.level = this.defaultLevel;
                    this.muteOn(item);
                }
                else {
                    item.play = false;
                }
            });
        }
        else {
            this.sounds.map((item) => {
                item.play = false;
            });
            const index = random(0, this.presets[group].length - 1, ((_a = this.presetSelected) === null || _a === void 0 ? void 0 : _a.group) == group ? this.presetSelected.index : undefined);
            for (let preset of this.presets[group][index]) {
                for (let item of this.sounds) {
                    if (item.key == preset.key) {
                        item.play = true;
                        item.level = preset.level;
                        this.muteOn(item);
                    }
                }
            }
            this.presetSelected = {
                group: group,
                index: index
            };
        }
    }
}
SoundsCtrl.$inject = ['$scope', '$filter', '$q'];
const appModule = ng.module('app');
appModule.requires.push('rzSlider');
appModule.component('gameSounds', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: SoundsCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('sounds/');
    }]);
